import React, { useState, useEffect } from 'react'

import Banner from '../partials/banner/banner'
import Header from '../components/header/header'
import Layout from '../layout/layout'
import DescriptionSection from '../sections/about/descriptionSection/descriptionSection'
import FeatureSection from '../sections/about/featureSection/featureSection'
import ContentWrap from '../components/contentWrap/contentWrap'

import axios from '../utils/axios'
import Loader from '../partials/loader/loader'

import contactQuery from '../utils/contactQuery'
import Footer from '../partials/footer/footer'

const About = props => {
  const [respData, setRespData] = useState({})
  const [isDataFetched, setIsDataFetched] = useState(false)

  useEffect(() => {
    const fc = async () => {
      const response = await axios({
        data: JSON.stringify({
          query: `{
          aboutwe{
            img{
              url
            }
            headA
            headB
            headDesc
            desc
            imgHeader{
              url
            }
          }
          allAboutweFeatures{
            title
            desc
          }
        ${contactQuery}
        }
        
        `,
        }),
      })

      setRespData(response.data.data)
      // console.log(response.data.data)
      setIsDataFetched(true)
    }
    fc()
  }, [])

  return (
    <>
      {<Loader isDataLoaded={isDataFetched} />}

      <Layout title="o Nas">
        {isDataFetched && (
          <>
            <Banner img={respData.aboutwe.imgHeader.url}>O nas</Banner>

            <ContentWrap>
              <DescriptionSection data={respData.aboutwe} />
              <Header h2 firstLetter align={'left'}>
                {respData.aboutwe.headB}
              </Header>
              <ul>
                {respData.allAboutweFeatures.map(el => (
                  <li>
                    <FeatureSection obj={el} />
                  </li>
                ))}
              </ul>
            </ContentWrap>
            <Footer data={respData.contact} />
          </>
        )}
      </Layout>
    </>
  )
}

export default About
