import React from 'react'
import ReactMarkdown from 'react-markdown'

import styled from 'styled-components'
import ContentWrap from '../../../components/contentWrap/contentWrap'
import Header from '../../../components/header/header'
import Desc from '../../../components/desc/desc'
import Fade from 'react-reveal/Fade'

const StyledContentWrap = styled(ContentWrap)`
  margin-left: 30px;
`
const StyledHeader = styled(Header)`
  margin-bottom: 10px;
`

const DescriptionSection = props => {
  return (
    <Fade>
      <StyledContentWrap as={'article'}>
        <StyledHeader as={'h3'} align="left" yellowRect>
          <ReactMarkdown
            disallowedTypes={['paragraph']}
            unwrapDisallowed
            children={props.obj.title}
          />
        </StyledHeader>
        <Desc align="left">
          {/* <ReactMarkdown
            disallowedTypes={['paragraph']}
            unwrapDisallowed
            children={props.obj.desc}
          /> */}
          <div
            dangerouslySetInnerHTML={{ __html: props.obj.desc }}
            className="cms-text"
          />
        </Desc>
      </StyledContentWrap>
    </Fade>
  )
}

export default DescriptionSection
