import React from 'react'
import ReactMarkdown from 'react-markdown'

import styled from 'styled-components'

import Desc from '../../../components/desc/desc'
import Header from '../../../components/header/header'
import ContentWrap from '../../../components/contentWrap/contentWrap'

const TextImgWrap = styled.section`
  /* display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 30px 0 10px; */

  /* @media (min-width: 960px) { */
  /* justify-content: space-between; */
  /* flex-wrap: nowrap; */
  /* align-items: flex-start; */
  /* } */

  display: grid;
  grid-template-areas: 'head' 'img' 'desc';
  row-gap: 30px;

  @media (min-width: 960px) {
    grid-template-areas: 'head img' 'desc desc';
    row-gap: 50px;
  }
`

const StyledImg = styled.div`
  width: 100%;
  height: 200px;
  max-width: 380px;
  min-width: 200px;
  border-radius: 10px;
  background-image: url(${props => props.srcImg});
  background-position: center;
  background-size: cover;
  margin: 0;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    background: ${({ theme }) =>
      'linear-gradient(90deg, ' +
      theme.colorYellow +
      ', ' +
      theme.colorOrange +
      ')'};
    transform: translate(10px, 10px);
    border-radius: 10px;
  }
  @media (min-width: 960px) {
    /* max-width: 200px; */
    margin: 0;
  }
`
const StyledDesc = styled(Desc)`
  grid-area: ${props => props.area || null};
  max-width: 400px;
  @media (min-width: 960px) {
    padding-right: 30px;
    max-width: 100%;
  }
`

const DescriptionPage = props => {
  return (
    <ContentWrap as={'main'}>
      <Header h2 firstLetter align="left">
        <ReactMarkdown
          disallowedTypes={['paragraph']}
          unwrapDisallowed
          children={props.data.headA}
        />
      </Header>

      <TextImgWrap>
        <StyledDesc align="head">
          <div
            dangerouslySetInnerHTML={{ __html: props.data.headDesc }}
            className="cms-text"
          />
        </StyledDesc>

        <StyledImg srcImg={props.data.img.url} />

        <StyledDesc align="left" area="desc" align="desc">
          <div
            dangerouslySetInnerHTML={{ __html: props.data.desc }}
            className="cms-text"
          />
        </StyledDesc>
      </TextImgWrap>
    </ContentWrap>
  )
}

export default DescriptionPage
